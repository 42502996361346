const generateAvailableScheduleTimes = (availableHours) => {

  return `
    <div class="col-md-12">
      <div class="form-group" id="available-schedule-times">
        <label for="time">Horários disponíveis</label>
        <select class="form-control time" name="time" id="time" required>
        <option value="" hidden selected hidden>Escolha um horário</option>
          ${availableHours.map((option) => (
            `<option value="${option.hour}">${option.hour}</option>`
          )).join("\n")}
        </select>
      </div>
    </div>
  `;
}

const generateAvailableScheduleLoading = () => {
  
  return `
    <div class="col-md-12">
      <p class="text-center">Carregando...</p>
    </div>
  `;
}

const generateAvailableScheduleError = (error) => {

  return `
    <div class="col-md-12">
      <span>Um erro ocorreu, por favor tente novamente,</span>
      <p>${error}</p>
    </div>
  `;
}


export {
  generateAvailableScheduleTimes,
  generateAvailableScheduleLoading,
  generateAvailableScheduleError
};