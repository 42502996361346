import { getScheduleHourList } from '../Api.js';
import {
  generateAvailableScheduleTimes,
  generateAvailableScheduleLoading,
  generateAvailableScheduleError
} from './templates.js';

const questionsAndAnswers = {
  "does-have-digital-certificate": "",
  "is-juridical-person": "",
  "which-certifate": "",
};

// cookies variables
const localPref = JSON.parse(localStorage.getItem('cookies'))
const sectionCooki = document.getElementById('cookies-section')
const buttonCooki = document.getElementById('cookies-acept')

const backButtons = Array.from(document.querySelectorAll('.btn-go-back'));
const quizQuestions = Array.from(document.querySelectorAll('.input-quiz'));
const dateSchedulingElement = document.getElementById('date_scheduling');
const schedulingHoursWrapperElement = document.getElementById('scheduling_hours');

window.onload = () => {
  if (localPref?.accepted) {
    removeCookieWarning();
    return;
  }
  sectionCooki.classList.remove('d-none');
};

const toggleQuestionClassList = ({ current, next }) => {
  current.classList.add('d-none');
  next.classList.remove('d-none');
}

const handleQuestionOptionClick = ({ target }) => {
  const inputHidden = document.getElementById(target.name);
  const { next, current, questionName } = inputHidden.dataset;
  const currentElement = document.getElementById(current);
  const nextElement = document.getElementById(next);
  const [digitalCertificateQuestion] = Array
    .from(document.querySelectorAll("[name=digital_certificate]"))
    .filter((question) => question.checked);
  const isJuridicalQuestion = document.getElementById('has_cnpj');

  questionsAndAnswers[questionName] = target.value;

  if (digitalCertificateQuestion.value == 'no') {
    isJuridicalQuestion.dataset.next = 'certificate-list';
    questionsAndAnswers['which-certifate'] = false;

    toggleQuestionClassList({
      current: currentElement,
      next: nextElement,
    })
    return;
  } else {
    isJuridicalQuestion.dataset.next = 'which-certifate';
  }

  console.log(questionsAndAnswers);

  if (next == 'certificate-list') {
    console.log('last-question');
    console.log('nextElement', nextElement)
    // faz a request aqui pra escolher o produto
  }

  toggleQuestionClassList({
    current: currentElement,
    next: nextElement
  });
};

const handleGoToPreviousQuestion = ({ target }) => {
  toggleQuestionClassList({
    current: document.getElementById(target.dataset.current),
    next: document.getElementById(target.dataset.next)
  })
}

const handleScheduleDateSelection = async (intendedDate) => {
  const availableScheduleData = await getScheduleHourList(intendedDate);

  if (availableScheduleData.error) {
    return generateAvailableScheduleError(availableScheduleData.error);
  }

  const availableScheduleTimeListElement = generateAvailableScheduleTimes(availableScheduleData);

  return availableScheduleTimeListElement;
};


// $(document).on('change', '#date', function (){
//   const self = $(this).val();
//   const [year, month, day] = self.split('-');
//   const updatedMonth = month - 1 < 9 ? `0${+(month - 1)}` : +(month - 1);
//   const actualDate = new Date(+year, +updatedMonth, +day);
//   const actualDay = actualDate.getDay();

//   const instancedDate = new Date();
//   const instancedYear = instancedDate.getFullYear();
//   const instancedMonth = instancedDate.getMonth();
//   const instancedDay = instancedDate.getDate();
//   const validationDate = new Date(instancedYear, instancedMonth, instancedDay);

//   if (actualDay === 0 || actualDay === 6) {
//     alert('Por favor, selecione um dia útil.');
//     $(this).val('');
//     return;
//   }

//   if (actualDate - validationDate < 0) {
//     alert(`Por favor, insira uma data maior ou igual que a de hoje`);
//     $(this).val('');
//     return;
//   }

//   const hours = [
//     '08:00', '08:15', '08:30', '08:45',
//     '09:00', '09:15', '09:30', '09:45',
//     '10:00', '10:15', '10:30', '10:45',
//     '11:00', '11:15', '11:30', '11:45',
//     '13:00', '13:15', '13:30', '13:45',
//     '14:00', '14:15', '14:30', '14:45',
//     '15:00', '15:15', '15:30', '15:45',
//     '16:00', '16:15', '16:30', '16:45',
//     '17:00', '17:15', '17:30', '17:45',
//   ];

//   const date = self;

//   $.ajax({
//     url: base_url+'sales/scheduling_block',
//     method: 'post',
//     dataType: 'json',
//     data: {date},
//     success: function (data){
//       console.log(data);
//       if (data === true) {
//         alert(`Não funcionaremos nesta data`);
//         $('#date').val('');
//         return;
//       }
//     }
//   });

//   $.ajax({
//     url: base_url+'sales/isset_scheduling',
//     method: 'post',
//     dataType: 'json',
//     data: {date},
//     success: function (data){
//       // console.log(data);
//       $('.time').html('<option value="" selected disabled></option>');
//       $.each(hours, function (index, value){
//         $('.time').append(`<option value="${value}">${value}</option>`);
//       });
//       let option = $('.time > option');
//       Array.from(option).forEach((valor) => {
//         Array.from(data).forEach((scheduleValue) => {
//           let certifiers = data.length - 1;
//           if (scheduleValue[0].quantity >= data[certifiers]) {
//             if (valor.value == scheduleValue[0].time) {
//               valor.style.color = 'red';
//               valor.setAttribute('disabled', 'disabled');
//             }
//           }
//         });
//       });
//     }
//   });
// });

dateSchedulingElement?.addEventListener("change", async ({ target }) => {
  const [year, month, day] = target.value.split('-');
  const updatedMonth = month - 1 < 9 ? `0${+(month - 1)}` : +(month - 1);
  const actualDate = new Date(+year, +updatedMonth, +day);
  const actualDay = actualDate.getDay();

  const instancedDate = new Date();
  const instancedYear = instancedDate.getFullYear();
  const instancedMonth = instancedDate.getMonth();
  const instancedDay = instancedDate.getDate();
  const validationDate = new Date(instancedYear, instancedMonth, instancedDay);

  if (actualDay === 0 || actualDay === 6) {
    alert('Por favor, selecione um dia útil.');
    target.value = '';
    return;
  }

  if (actualDate - validationDate < 0) {
    alert(`Por favor, insira uma data maior ou igual que a de hoje`);
    target.value = '';
    return;
  }

  schedulingHoursWrapperElement.innerHTML = generateAvailableScheduleLoading();
  
  const scheduleIsAvailable = await handleScheduleDateSelection(target.value);
  schedulingHoursWrapperElement.innerHTML = scheduleIsAvailable;
});

backButtons.forEach((backButton) => {
  backButton.addEventListener('click', handleGoToPreviousQuestion);
});

quizQuestions.forEach((quizQuestion) => {
  quizQuestion.addEventListener('click', handleQuestionOptionClick)
})


function aceptCookies() {
  localStorage.setItem('cookies', JSON.stringify({ accepted: true }));

  removeCookieWarning();
}

function removeCookieWarning() {
  sectionCooki.remove();
}

buttonCooki.addEventListener('click', aceptCookies)



const accordionTogglers = Array.from(document.getElementsByClassName("btn-accordion"))

accordionTogglers.forEach((accordionToggler) => {
  const { arrow } = accordionToggler.dataset;

  accordionToggler.addEventListener('click', () => {
    event.preventDefault();
    const arrowElement = document.getElementById(arrow);
    
    arrowElement.classList.toggle('open');
  })
});


