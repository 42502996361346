const BASE_URL = 'http://localhost/seguraid';
const CSRFToken = document.querySelector('[name="csrf-token"]').content;

const requestCertificateList = async ({
}) => {
  console.log('foo-bar');
  const body = new FormData();

  // try {
  //   const request = await fetch(BASE_URL, {
  //     method: 'POST', body
  //   })
  //   return request.json();
  // } catch (error) {
  //   return error;
  // }
};

// const updateClientData = async ({ formBody }) => {
//   const body = new FormData();
//   const request = await fetch(BASE_URL, {

//   });
// }

const getScheduleHourList = async (intendedDate) => {
  const body = new FormData();

  body.append('intended_date', intendedDate);

  try {
    const request = await fetch(`${BASE_URL}/get-schedule-hour-list`, {
      method: 'POST',
      headers: {
        "X-CSRF-TOKEN": CSRFToken,
      },
      body
    });
    return request.json();
  } catch (error) {
    console.log('error', error);
    return error;
  }
};

const checkSchedule = async (intendedDate) => {
  const body = new FormData();

  body.append('intended_date', intendedDate);

  try {
    const request = await fetch(`${BASE_URL}/check-schedule-availability`, {
      method: 'POST',
      headers: {
        "X-CSRF-TOKEN": CSRFToken,
      },
      body
    });
    return request.json();
  } catch (error) {
    return error;
  }
};

export {
  BASE_URL,
  requestCertificateList,
  getScheduleHourList,
  checkSchedule
};
